<template>
  <div>
    <div class="bg_box"></div>
    <div class="centerbox">
      <headbox 
        title="设置"
        bgColor="#fff"
        isborder="true"
        :isbackperson="true"
        ></headbox>
      <div class="flex-between bgwhite">
        <div class="flex">
          <div class="imgbox" v-if="userInfo.auth == 'UNPOST'">
            <img src="@/assets/images/no_cerhead.png" />
          </div>
          <div class="imgbox" v-if="userInfo.auth == 'POST'">
            <img src="@/assets/images/no_cerhead.png" />
          </div>
          <div class="imgbox" v-if="userInfo.auth == 'DONE'">
            <template v-if="userInfo.avatar">
              <img :src="userInfo.avatar" />
            </template>
            <template v-else>
              <img src="@/assets/images/people.png" />
            </template>
          </div>
          <div class="phonebox">{{ userInfo.phone }}</div>
        </div>
        <div class="statusbox" v-if="userInfo.auth == 'UNPOST' || userInfo.auth == 'FAIL'" @click="gocer">
          请认证<i class="el-icon-arrow-right"></i>
        </div>
        <div class="statusbox" v-else-if="userInfo.auth == 'POST'">
          认证中<i class="el-icon-arrow-right"></i>
        </div>
        <div class="statusbox overcer" v-else-if="!userInfo.hasOwnProperty('license')" @click="license">
          待营业执照认证<i class="el-icon-arrow-right"></i>
        </div>
        <div class="statusbox overcer" v-else-if="!userInfo.hasOwnProperty('sign')" @click="authgraph">
          待签名<i class="el-icon-arrow-right"></i>
        </div>
<!--        <div class="statusbox overcer" v-else-if="!userInfo.hasOwnProperty('faceVerify')" @click="face">
          待人脸认证<i class="el-icon-arrow-right"></i>
        </div>-->
        
        <div class="statusbox overcer" v-else-if="userInfo.auth == 'DONE'">
          已认证
        </div>
      </div>
      <!-- <div class="flex-between  bgwhite">
        <div>推荐码</div>
        <div class="capy">
          <span>{{userInfo.referrerCode}}</span>
          <i class="el-icon-document-copy" @click="copy(userInfo.referrerCode)"></i></div>
      </div> -->
      <div class="flex-between  bgwhite" @click="repas">
        <div>修改密码</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>
<!--      <div class="flex-between  bgwhite" @click="gobank">
        <div>银行账号</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>-->
      <div class="flex-between  bgwhite" @click="suspends">
        <div>悬浮窗报价</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="flex-between  bgwhite" @click="goAgreements" v-if="userInfo.auth == 'DONE' && userInfo.hasOwnProperty('sign')">
        <div>相关协议</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>
      <!-- 退出登录 -->
      <div class="loginout" @click="loginout">退出登录</div>
    </div>
  </div>
</template>
<script>
import headbox from '@/components/head.vue'
export default {
  components:{
    headbox
  },
  data() {
    return {
      userInfo: ""
    };
  },
  mounted() {
    const getUser = JSON.parse(localStorage.getItem("userinfo"));
    this.userInfo = getUser;
  },
  methods: {
    suspends(){
      this.$router.push({
        name:'suspends'
      })
    },
            // 复制功能
    copy(txt){
            this.$copyText(txt).then(()=>{
                this.$toast('卡号已复制到剪贴板')
            })
    },
/*    face(){
      if (this.userInfo.hasOwnProperty('faceVerify') ) {

        return false
      }else{
          
      this.$router.push({name:'face'})
      }
    },*/
    license(){
      if (this.userInfo.hasOwnProperty('license') ) {
        return false
      }
      else{
        this.$router.push({
          name: "license",
          query: { pricing: true }
        })
      }
    },
    authgraph(){
      if (this.userInfo.hasOwnProperty('sign') ) {
        return false
      }
      else{
        this.$router.push({
          name: "AccountContract",
          query: { pricing: true }
        })
      }
    },
    // 去认证
    gocer() {
        this.$router.push({ name: "certification" });
    },
    // 相关协议
    goAgreements(){
      this.$router.push({ name: "Agreements" });
    },
    loginout() {
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("token");
      localStorage.removeItem("moible");
      localStorage.removeItem("userinfo");
      localStorage.setItem('isSevrice',0)
      this.fishkefu.logout()
      this.$router.push({ name: "login" });
 
    },
    gobank() {
         if (this.userInfo.auth == 'UNPOST') {
          //   并未认证
            this.$toast('请认证')
            setTimeout(()=>{
            this.$router.push({
            name:'certification'
            })
              },1500)
              return false
          }
        if (this.userInfo.auth == 'POST') {
                // 正在认证
            this.$toast('正在审核,请耐心等待')
            return false
          }
      this.$router.push({ name: "bank" });
    },
    repas() {
      this.$router.push({ name: "respas",query:{typePath:'loginOut'} });
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/index.sass";

.el-icon-document-copy{
  margin-left: 6px;
  color: #999999;
  font-size: 40px;
}
.overcer {
  color: #FF2D2E;
}
.statusbox {
  color: #FF2D2E;
  padding-top: 18px;
}
.imgbox {
  margin-right: 10px;
  width: 72px;
  height: 72px;
  img {
    border-radius: 50%;
    overflow: hidden;
  }
}
.loginout {
  width: 90%;
  height: 88px;
  margin: 50px auto;
  text-align: center;
  border-radius: 50px;
  color: #fff;
  font-size: 36px;
  line-height: 88px;
  background: #FF2D2E;
  // $btn_color: #ffb606;
}
.phonebox {
  padding-top: 22px;
}
.bg_box {
  // background: red;
  background: #f7f7f7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.bgwhite {
  font-size: 32px;
  padding: 35px 30px;
  background: #fff;
  margin-bottom: 20px;
  i {
    margin-top: 10px;
  }
}
.centerbox {
  position: relative;
  z-index: 1;
}
</style>
